<template>
  <div class="user">
    <div class="user-title">
      <div class="user-title-name">员工列表</div>
      <div class="item">
        <span>员工名称或帐号</span>
        <el-input size="small" class="item-input" v-model="pagination.name" placeholder="请输入员工名称或帐号"/>
      </div>
    </div>
    <el-table
      class="user-container"
      :data="users"
      :loading="loading"
      tooltip-effect="dark"
      ref="dataTable"
      :row-key="(row) => row.proveUuid"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        :reserve-selection="true"
        v-if="!canOpr"
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="proveUuid" label="员工编号"></el-table-column>
      <el-table-column prop="name" label="员工名称"></el-table-column>
      <el-table-column prop="mobile" label="联系方式"></el-table-column>

      <el-table-column label="员工身份">
        <div slot-scope="scope">
          <span v-if="scope.row.isMaster">主管</span>
          <span v-else>员工</span>
        </div>
      </el-table-column>
      <el-table-column label="操作" v-if="canOpr">
        <template slot-scope="scope">
          <el-button type="text"
                     @click.native="handleProveUnBind(scope.row.proveUuid)"
                     v-if="$btnCheck('org_architecture_department_proveOpr') && isSelf">移除员工
          </el-button>
          <el-button type="text"
                     @click.native="handleProveBind(scope.row.proveUuid)"
                     v-if="$btnCheck('org_architecture_department_proveOpr') && !isSelf">绑定员工
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      :page-size="pagination.limit"
      layout="total,prev, pager, next"
      @current-change="handleProveList"
      :total="pagination.total">
    </el-pagination>

  </div>

</template>

<script>

  export default {
    name: 'user',
    components: {},
    props: {
      canOpr: {
        type: Boolean,
        default: true,
      }
    },
    data: function () {
      return {
        hasSelectList: [],
        pagination: {
          name: '',
          uuid: '',
          isSelf: 1,
          start: 0,
          limit: 10,
          pageSize: 10,
          current: 1,
          total: 1,
        },
        userStatus: [
          {
            value: 0,
            name: '全部',
          },
          {
            value: 1,
            name: '申请中',
          },
          {
            value: 2,
            name: '已拒绝',
          },
          {
            value: 3,
            name: '正常使用',
          },
          {
            value: 4,
            name: '已停用',
          }
        ],
        loading: false,
        users: [],
      }
    },
    mounted: function () {
    },
    methods: {
      loadProve(uuid, isSelf = true) {
        this.pagination.uuid = uuid
        this.isSelf = isSelf
        let pagination = Object.assign({}, this.pagination)
        pagination.isSelf = isSelf ? 1 : 0;
        if (uuid) {

          this.$api('org.department.proveUnion', pagination).then(data => {
            this.pagination.total = parseInt(data.total)
            this.users = data.list || []
          })
        } else {
          this.$api('org.user.managerUnion', pagination).then(data => {
            this.pagination.total = parseInt(data.total)
            this.users = data.list || []
            let vue = this
            this.users.forEach((row, idx) => {
              if (this.hasSelectList.indexOf(row.proveUuid) >= 0) {
                this.$refs.dataTable.toggleRowSelection(vue.users[idx]);
              }
            })
          })
        }
      },
      handleProveList(page) {
        this.pagination.start = (parseInt(page, 10) - 1) * this.pagination.limit
        this.pagination.current = page
        let pagination = Object.assign({}, this.pagination)
        pagination.onChange = null
        if (this.pagination.uuid) {
          this.$api('org.department.proveList', pagination).then(data => {
            this.users = data || []
          })
        } else {
          this.$api('org.user.list', pagination).then(res => {
            this.users = res.data || []
            this.users.forEach((row, idx) => {
              if (this.hasSelectList.indexOf(row.proveUuid) >= 0) {
                this.$refs.dataTable.toggleRowSelection(this.users[idx]);
              }
            })
          })
        }
      },
      handleProveBind(proveUuid) {
        this.$api('org.department.proveBind', {}, {
          uuid: this.pagination.uuid,
          proveUuid: [proveUuid],
        }).then(() => {
          this.$message.success('添加成功')
          this.loadProve(this.pagination.uuid, this.isSelf)
        })
      },
      handleProveUnBind(proveUuid) {
        this.$api('org.department.proveUnbind', {}, {
          uuid: this.pagination.uuid,
          proveUuid: proveUuid,
        }).then(() => {
          this.$message.success('已移除')
          this.loadProve(this.pagination.uuid, this.isSelf)
        })
      },

      handleSelectionChange(val) {
        let vue = this
        let newArr = val.filter((v) => {
          return vue.hasSelectList.indexOf(v.proveUuid) < 0
        })

        let newProveUuidArr = []
        newArr.forEach(v => {
          newProveUuidArr.push(v.proveUuid)
        })

        vue.hasSelectList = vue.hasSelectList.concat(newProveUuidArr)
      },
      initSelectList(proveUuid) {
        this.hasSelectList = proveUuid || []
      },

      handleGetProveUUID() {
        return this.hasSelectList
      }
    },
  }
</script>

<style lang="scss" scoped>
  .user {
    &-title {
      display: flex;
      align-content: center;
      align-items: baseline;

      &-name {
        @include font-medium();
        font-weight: 500;
        text-align: left;
        flex: 1;
      }

      .item {
        margin-bottom: $middle-space;

        .item-input {
          width: 176px;
        }

        span {
          width: 100px;
          display: inline-block;
          margin-right: $small-space;
          text-align: right;
        }
      }
    }
  }
</style>
