<template>
  <div class="role">
    <div class="role-title">
      岗位权限
    </div>
    <el-row class="role-body">

      <el-col :span="5" class="role-list">
        <div v-for="(role, key) in roles" :key="key"
             class="role-item"
             :class="{active: roleInfo.uuid === role.uuid}"
             @click="loadRole(role)">
          <p class="role-item-name">
            <span class="name">{{role.name}}</span>
            <span class="operate"
                  v-if="$btnCheck('org_architecture_role_delete') && org.uuid !== role.uuid"
                   @click.stop.prevent="loadDelete(role.uuid)">
            <i class="el-icon-delete" title="删除"/>
          </span>
          </p>
          <p class="role-item-remark">{{role.remark}}</p>
        </div>
        <div class="role-plus" @click="showRole=true" v-if="$btnCheck('org_architecture_role_create')">
          <i class="el-icon-circle-plus-outline"/>
          添加角色
        </div>

      </el-col>
      <el-col :span="19" class="role-body-pow">
        <div class="role-container" ref="container">
          <div class="container">
            <p class="container-title">角色信息</p>
            <el-row class="container-item">
              <el-col :span="3" :offset="3" class="container-item-text">角色名称:</el-col>
              <el-col :span="10">
                <el-input placeholder="请输入角色名称" v-model="roleInfo.name"/>
              </el-col>
            </el-row>
            <el-row class="container-item">
              <el-col :span="3" :offset="3" class="container-item-text">角色说明:</el-col>
              <el-col :span="10">
                <el-input type="textarea" placeholder="至多输入15个字" v-model="roleInfo.remark" :auto-size="{ minRows: 3, maxRows: 5 }"/>
              </el-col>
            </el-row>
            <el-row class="container-item" v-if="$btnCheck('org_architecture_role_proves')">
              <el-col :span="3" :offset="3" class="container-item-text">相关用户:</el-col>
              <el-col :span="10">
                <div>
                  <el-tag size="small" class="tag-add" v-if="$btnCheck('org_architecture_role_proveOpr')" @click="handleShowProve">
                    <el-icon type="plus"/>
                    添加员工
                  </el-tag>
                  <el-tag size="small" v-for="(prove, idx) in proves"
                          :closable="$btnCheck('org_architecture_role_proveOpr')"
                          @close="proveRoleUnbind(prove, idx)" :key="idx">
                    {{prove.name}}
                  </el-tag>
                </div>
                <p class="remark">注意: 员工如果没有任何角色,则无法登录系统</p>
              </el-col>
            </el-row>

          </div>

          <div class="container">
            <p class="container-title">角色权限</p>
            <role-tree :modules="modules"
                       :notEdit="org.uuid === roleInfo.uuid"
                       :moduleChange="moduleChange"
                       :change="buttonChange"></role-tree>
          </div>
          <div class="submit">
            <el-button type="primary" @click="handleUpdate"
                       v-if="$btnCheck('org_architecture_role_edit') && org.uuid !== roleInfo.uuid">保存</el-button>
          </div>
        </div>
      </el-col>
      <el-dialog
        width="520px"
        v-if="$btnCheck('org_architecture_role_create')"
        :visible.sync="showRole"
        title="新增角色"
      >
        <el-form label-width="80px">
          <el-form-item label="角色名称">
            <el-input v-model="createRole.name" placeholder="请输入角色名称"/>
          </el-form-item>

          <el-form-item label="角色说明">
            <el-input type="textarea" placeholder="至多输入15个字" v-model="createRole.remark" :auto-size="{ minRows: 3, maxRows: 5 }"/>
          </el-form-item>

          <el-form-item>
            <el-button size="small" type="primary" @click="handleRoleCreate">确认</el-button>
            <el-button size="small" @click="showRole = false">取消</el-button>
          </el-form-item>

        </el-form>
      </el-dialog>
      <el-dialog
        :visible.sync="showProve"
        title="绑定员工"
        width="760px"
        @ok="handleProveBind"
      >
        <employee ref="employee" :can-opr="false"></employee>
        <el-button size="small" type="primary" @click="handleProveBind">确认</el-button>
        <el-button size="small" @click="showProve = false">取消</el-button>
      </el-dialog>
    </el-row>
  </div>

</template>

<script>
  import {roleList, roleUnion, roleCreate,roleProves,
    roleUpdate, roleDelete, roleUnbind, roleBind} from '@/api/org/role'
  import RoleTree from "./components/role_tree"
  import {mapGetters} from "vuex";
  import Employee from "./components/user";

  export default {
    name: '',
    components: {
      RoleTree,
      Employee
    },
    computed: {
      ...mapGetters(['org']),
    },
    mounted: function () {
      this.loadRoleList()
    },
    data: function () {
      return {
        showProve: false,
        showRole: false,
        labelCol: {
          xs: {span: 24},
          sm: {span: 5},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
        createRole: {
          name: '',
          remark: '',
        },
        roleInfo: {
          uuid: '',
          name: '',
          remark: '',
          moduleButtonUuid: [],
          deleteBtnUuid: [],
        },
        roles: [],
        proves: [],
        modules: [],
      }
    },
    methods: {
      proveRoleUnbind(prove, idx) {
        roleUnbind({
          uuid: this.roleInfo.uuid,
          proveUuid: prove.proveUuid
        }).then(() => {
          this.proves.splice(idx, 1)
          this.$message.success('解除成功')
        })
      },

      handleProveBind() {
        let proveUUID = this.$refs.employee.handleGetProveUUID()
        if (!proveUUID) {
          this.$message.warning('请选取对应员工')
        } else {
          roleBind({
            uuid: this.roleInfo.uuid,
            proveUuid: proveUUID,
          }).then(() => {
            this.$message.success('绑定成功')
            this.showProve = false
            roleProves({uuid: this.roleInfo.uuid}).then(res => {
              this.proves = res.data || []
            })
          })
        }
      },

      handleShowProve() {
        this.showProve = true
        let uuids = []
        this.proves.forEach(v => {
          uuids.push(v.proveUuid)
        })
        this.$nextTick(function () {
          this.$refs.employee.initSelectList(uuids)
          this.$refs.employee.loadProve()
        })
      },

      loadRoleList() {
        roleList().then(res => {
          this.roles = res.data || []
          this.loadRole(this.roles[0])
        })
      },

      loadRole(role) {
        this.roleInfo.uuid = role.uuid
        this.roleInfo.name = role.name
        this.roleInfo.remark = role.remark
        this.roleInfo.moduleButtonUuid = []
        this.roleInfo.deleteBtnUuid = []
        roleUnion({uuid: role.uuid}).then(res => {
          this.proves = res.data.proves
          this.modules = res.data.modules
        })
      },
      loadDelete(uuid) {
        let vue = this
        vue.$confirm('删除角色可能导致员工无法登录, 是否继续','删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          roleDelete({uuid: uuid}).then(() => {
            vue.loadRoleList()
            vue.$message.success('删除成功')
          })
        }) ;
      },
      buttonChange(button) {
        if (button.isCheck) {
          for (let i = 0; i < this.roleInfo.deleteBtnUuid.length; i++) {
            if (this.roleInfo.deleteBtnUuid[i] === button.uuid) {
              this.roleInfo.deleteBtnUuid.splice(i, 1)
              break
            }
          }
          this.roleInfo.moduleButtonUuid.push(button.uuid)
        } else {
          for (let i = 0; i < this.roleInfo.moduleButtonUuid.length; i++) {
            if (this.roleInfo.moduleButtonUuid[i] === button.uuid) {
              this.roleInfo.moduleButtonUuid.splice(i, 1)
              break
            }
          }
          this.roleInfo.deleteBtnUuid.push(button.uuid)
        }
      },
      handleRoleCreate() {
        if (!this.createRole.name) {
          this.$message.warning('请输入角色名称')
          return
        }

        roleCreate(this.createRole).then(res => {
          this.roles.push(res.data)
          this.showRole = false
        })
      },
      handleUpdate() {
        if (!this.roleInfo.name) {
          this.$message.warning('请输入角色名称')
          return
        }

        roleUpdate(this.roleInfo).then(() => {
          this.$message.success('更新成功')
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .role {
    @include container();
    padding-bottom: 0;

    &-title{
      @include page-title();
    }

    &-body{
      padding-top: $middle-space;
      &-pow{
        height: 100%;
        overflow-y: scroll;
      }
    }

    .remark {
      margin-top: $small-space;
      @include font-little();
      color: $gray-8;
    }

    &-list {
      //padding-top: $middle-space;
      overflow-y: scroll;

      .active {
        background-color: $theme-light-9;
        border-right: 1px solid $theme-color;
      }

      .role-item {
        cursor: pointer;
        height: 80px;
        border-bottom: 1px solid $content-border-color;

        &-name {
          margin-left: $middle-space;
          padding-top: $small-space;
          padding-right: $middle-space;

          .name {
            @include font-medium-s();
            font-weight: 500;
            color: $gray-9;
          }

          .operate {
            float: right;
            display: none;

            i {
              margin-right: $small-space;
            }

            i:hover {
              background-color: $theme-color;
              border-radius: 50%;
              padding: 4px;
              color: $color-white;
            }
          }
        }

        &-name:after {
          content: ' ';
          display: block;
          clear: both;
        }

        &-remark {
          margin-left: $middle-space;
          margin-top: $small-space;
          @include font-little();
          color: $gray-8;
        }
      }

      .role-item:hover {
        .operate {
          display: block;
        }
      }

      .role-plus {
        height: 64px;
        line-height: 64px;
        text-align: center;
        cursor: pointer;
      }

      .role-plus:hover {
        color: $theme-color;
      }

    }

    &-container {
      /*height: calc(100% - 40px);*/
      min-height: 640px;
      border-left: 1px solid $container-border-color;
      overflow-y: scroll;


      .container {
        /*position: relative;*/
        /*max-height: 360px;*/
        /*overflow-y: scroll;*/
        padding-left: $middle-space;

        .tag-add {
          background: #fff;
          border-style: dashed;
          cursor: pointer;
        }

        .tag-add:hover {
          color: $theme-color;
          border-color: $theme-color;
        }

        &-title {
          @include font-medium-s();
          font-weight: 500;
          margin-top: $middle-space;
          padding-bottom: 4px;
          padding-left: $small-space;
          color: $color-black;
          border-bottom: 1px solid $container-border-color;
          margin-bottom: $middle-space;
        }

        &-item {
          margin-top: $large-space;

          &-text {
            text-align: right;
            padding-right: $small-space;
          }
          .el-tag{
            margin-right: $small-space;
            margin-bottom: $small-space;
          }
        }


      }

      .submit {
        text-align: center;
        // border: 1px solid $container-border-color;
        border-left: none;
        padding: $small-space 0;
        margin-top: $middle-space;
        margin-bottom: $middle-space;

      }
    }

     .ant-modal-close-x {
      width: 96px !important;
    }
  }
</style>
