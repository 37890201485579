import request from '@/utils/request';

export function roleList() {
  return request({
    url: `/v1/org/role/list`,
    method: 'GET',
  })
}

export function roleUnion(params) {
  return request({
    url: `/v1/org/role/union`,
    method: 'GET',
    params
  })
}


export function roleCreate(data) {
  return request({
    url: `/v1/org/role/create`,
    method: 'POST',
    data
  })
}

export function roleDelete(params) {
  return request({
    url: `/v1/org/role/delete`,
    method: 'GET',
    params
  })
}

export function roleUpdate(data) {
  return request({
    url: `/v1/org/role/update`,
    method: 'POST',
    data
  })
}

export function roleProves(params) {
  return request({
    url: `/v1/org/role/proves`,
    method: 'GET',
    params
  })
}

export function roleUnbind(data) {
  return request({
    url: `/v1/org/role/proveUnbind`,
    method: 'POST',
    data
  })
}

export function roleBind(data) {
  return request({
    url: `/v1/org/role/proveBind`,
    method: 'POST',
    data
  })
}
