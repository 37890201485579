<template>
  <el-tabs
    v-if="modules"
    :default-active-key="defaultKey"
    :tab-position="mode">
    <el-tab-pane v-for="(module, modIdx) in modules" :key="module.uuid" :label="module.name">
      <role-tree :modules="module.modules" v-if="module.modules" :notEdit="notEdit" :change="change"></role-tree>
      <div class="module">
        <div class="module-item-button">
          <el-row>
            <el-col v-if="module.buttons && module.buttons.length > 0">
              <el-checkbox :disabled="notEdit"
                           :indeterminate="module.isIndeterminate"
                           v-model="module.all"
                           @change="handleSelect(module)"> 全选</el-checkbox>
            </el-col>
            <el-col :span="8" v-for="(button, idx) in module.buttons" :key="idx">
              <el-checkbox  v-model="button.isCheck"  :disabled="notEdit"  @change="onChange(modIdx,  idx)">
                {{button.name}}
              </el-checkbox>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  export default {
    name: 'RoleTree',
    props: {
      modules: {
        type: Array,
        default: function () {
          return []
        }
      },
      notEdit: {
        type: Boolean,
        default: false
      },
      change:{
        type:Function,
        default: function () {
        }
      },
    },
    components: {},
    mounted: function () {
      this.modules.map(item => {
        let m = {
          ...item,
          all:false,
          isIndeterminate: false,
        }
        this.isAll(m)

        return m
      })
      this.modules.sort((a, b) => {
        if (a.modules) {
          if (b.modules) {
            return a.uuid > b.uuid
          }
          return false
        } else {
          if (b.modules) {
            return true
          } else {
            return a.uuid > b.uuid
          }
        }
      })
    },
    data: function () {
      return {
        mode: 'left',
        defaultKey: '4f6f30939402000',
      }
    },
    methods: {
      handleSelect(module) {
        module.isIndeterminate = false
        module.buttons.forEach(item => {
          item.isCheck = module.all
          this.change(item)
        })
      },
      isAll(item) {
        let isAll = true
        let checkCnt = 0

        if (item.buttons) {
          for (let i = 0; i < item.buttons.length; i++) {
            if (item.buttons[i].isCheck) {
              checkCnt ++
            }
            if (isAll && !item.buttons[i].isCheck) {
              console.log(item.buttons[i].name, item.buttons[i].isCheck)
              isAll = false
            }
          }
        }



        item.all = isAll
        if (item.buttons) {
          item.isIndeterminate = (checkCnt !== item.buttons.length && checkCnt !== 0)
        }
      },
      onChange(modIdx, idx) {

        this.isAll(this.modules[modIdx])
        this.change(this.modules[modIdx].buttons[idx])
      }
    },
  }
</script>

<style lang="scss" scoped>
  .module {
    /*height: 296px;*/
    /*overflow-y: scroll;*/

    &-item {
      margin-bottom: $middle-space;

      &-button {
        margin: 0 $middle-space;

        .ant-checkbox-group  {
          width: 100%;
        }

        .ant-row  {
          .ant-col {
            margin-top: $small-space;
          }
        }
      }
    }

  }
</style>
